<template>
  <div>
    <b-list-group v-if="list" class="border-0 rounded-0">
      <b-list-group-item
        v-for="notification in notifications"
        :key="notification.id"
        :class="{ 'bg-light': !notification.notificated }"
        class="border-top-0 border-left-0 border-right-0"
      >
        <div class="row">
          <div class="col col-8" :class="{ 'text-muted': notification.notificated }">
            <h5
              class="text-black font-weight-bolder"
              :class="{ 'text-muted font-italic': notification.notificated }"
            >
              {{ notification.title }}
            </h5>
            <p
              class="mb-0 text-primary"
              :class="{ 'text-muted font-italic': notification.notificated }"
            >
              {{ notification.content }}
            </p>
            <p class="mb-0 text-sm text-muted">
              {{ $formatTimestamsFromFirebase(notification.created_at) }}
            </p>
          </div>
          <div class="col col-4 d-flex align-items-center justify-content-end">
            <NotificationsButton :notification="notification" />
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <b-container v-else class="my-10">
      <h2 class="text-center text-muted">No hay notificaciones</h2>
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import NotificationsButton from '@/components/Notifications/NotificationsButton'

export default {
  name: 'NotificationsList',

  components: {
    NotificationsButton
  },

  computed: mapGetters('UIModule', {
    notifications: 'getNotifications',
    list: 'isThereNotifications'
  }),

  methods: {
    ...mapActions('UIModule', ['markAsRead'])
  }
}
</script>
