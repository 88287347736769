<template>
  <base-view icon="bell" title="Notificaciones">
    <template #right-place>
      <div class="row">
        <b-button
          size="sm"
          variant="primary"
          @click="createNotification"
          class="col-4 offset-8 right-content"
        >
          Crear notification de prueba
        </b-button>
      </div>
    </template>

    <div class="card card-header-actions">
      <div class="card-header">
        Mis notificaciones
      </div>
      <div class="card-body p-0 no-body">
        <NotificationsList />
      </div>
    </div>
  </base-view>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import NotificationsList from '@/components/Notifications/NotificationsList'

export default {
  name: 'NotificationsView',

  components: {
    NotificationsList
  },

  computed: mapGetters('UIModule', { notifications: 'getNotifications' }),

  methods: {
    ...mapActions('UIModule', ['createNotification', 'markAsRead'])
  }
}
</script>
